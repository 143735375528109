import React from 'react';
import './App.css';
import { BrowserRouter, Route } from 'react-router-dom'
import NavBar from './components/NavBar';
import Home from './components/Home';
import Kolkata from './components/cities/Kolkata';
import Delhi from './components/cities/Delhi'
import Mumbai from './components/cities/Mumbai'
import Bangalore from './components/cities/Bangalore'
import Chennai from './components/cities/Chennai';
import Hyderabad from './components/cities/Hyderabad';
import Kanpur from './components/cities/Kanpur';
import Surat from './components/cities/Surat';
import Ahmedabad from './components/cities/Ahmedabad';
import Coimbatore from './components/cities/Coimbatore';
import Jaipur from './components/cities/Jaipur';
import Lucknow from './components/cities/Lucknow';


function App() {
    return (
        <BrowserRouter>
            <NavBar />
            <Route exact path = "/" component={Home} />
            <Route path = "/kolkata" component={Kolkata} />
            <Route path = "/delhi" component={Delhi} />
            <Route path="/mumbai" component={Mumbai} />
            <Route path="/chennai" component={Chennai} />
            <Route path="/bangalore" component={Bangalore} />
            <Route path="/hyderabad" component={Hyderabad} />
            <Route path="/coimbatore" component={Coimbatore} />
            <Route path="/ahmedabad" component={Ahmedabad} />
            <Route path="/jaipur" component={Jaipur} />
            <Route path="/kanpur" component={Kanpur} />
            <Route path="/lucknow" component={Lucknow} />
            <Route path="/surat" component={Surat} />
            
        </BrowserRouter>
    );
}

export default App;
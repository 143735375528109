import React from 'react';
import {Link} from 'react-router-dom';

const NavBar = () => (
    <nav className="navbar navbar-expand-lg navbar-dark static-top">
		<div className="container">
			<Link className="navbar-brand" to="/">Rainly</Link>
			<button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarResponsive" aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
				<span className="navbar-toggler-icon"></span>
			</button>
			<div className="collapse navbar-collapse" id="navbarResponsive">
				<ul className="navbar-nav ml-auto">
					<li className="nav-item dropdown">
						<a className="navbar-brand dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Select City(A-1)-India</a>
						<div className="dropdown-menu">
							<Link className="dropdown-item" to="/kolkata"><b>Kolkata</b></Link>
							<Link className="dropdown-item" to="/delhi"><b>Delhi</b></Link>
							<Link className="dropdown-item" to="/mumbai"><b>Mumbai</b></Link>
							<Link className="dropdown-item" to="/chennai"><b>Chennai</b></Link>
							<Link className="dropdown-item" to="/bangalore"><b>Bangalore</b></Link>
							<Link className="dropdown-item" to="/hyderabad"><b>Hyderabad</b></Link>
						</div>
					</li>
				
				
					<li className="nav-item dropdown">
					<a className="navbar-brand dropdown-toggle" href="#" id="navbardrop" data-toggle="dropdown">Select City(A)-India</a>
					<div className="dropdown-menu">
						<Link className="dropdown-item" to="/ahmedabad"><b>Ahmedabad</b></Link>
						<Link className="dropdown-item" to="/coimbatore"><b>Coimbatore</b></Link>
						<Link className="dropdown-item" to="/jaipur"><b>Jaipur</b></Link>
						<Link className="dropdown-item" to="/kanpur"><b>Kanpur</b></Link>
						<Link className="dropdown-item" to="/lucknow"><b>Lucknow</b></Link>
						<Link className="dropdown-item" to="/surat"><b>Surat</b></Link>
					</div>
					</li>
					<li className="nav-item">
						<a target="_blank" className="navbar-brand" href="https://abhishek.onrender.com">Creator</a>
					</li>
				</ul>
			</div>
		</div>
	</nav>
);

export default NavBar;
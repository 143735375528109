import React from 'react'

export default class Hyderabad extends React.Component
{
    constructor(props){
    // Required step: always call the parent class' constructor
        super(props);
        this.state = 
        {
            CityName: null,
            Temperature: null,
            Condition: null,
            isLoaded: false,
        }
    }

    async componentDidMount()
    {
        const apiKey = "8f7dfcf3b43d0c401d33a212979b7e78";
        const units = "Metric";
        const weatherApi = `https://api.openweathermap.org/data/2.5/weather?q=Hyderabad&APPID=${apiKey}&units=${units}`;
        const response = await fetch(weatherApi);
        const data = await response.json();
        this.setState({CityName: data.name});
        this.setState({Temperature: Math.floor(data.main.temp)});
        this.setState({Condition: data.weather[0].description})
        this.setState({isLoaded:true});
        this.setState({humidity: data.main.humidity});
    }

    render()
    {
        
        if(this.state.isLoaded===false)
        {
            return(
                <div className="content">
                    <p className="medium">Please wait a moment...</p>
                </div>
                
            );
            
        }

       else
       {
            return(
                <div className="content">
                    <p className="medium">{ this.state.CityName }</p>
                    <p className="big">{ this.state.Temperature } °C</p>
                    <p className="small">{ this.state.Condition }</p>
                    <p className="small">Humidity:{ this.state.humidity} %</p>
                </div>
                
            );
       }
    }
}
import React from 'react'

export default class Search extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            CityName: null,
            Temperature: null,
            Condition: null,
            degree:null,
            humidity:null,
            humidityValue:null,
            percent:null,
            country:null,
            countryName:null,
        }
    }

    getWeather = async(e) => 
    {
        e.preventDefault()
        const apiKey = "8f7dfcf3b43d0c401d33a212979b7e78";
        const units = "Metric";
        const city = e.target.elements.city.value;
        const weatherApi = `https://api.openweathermap.org/data/2.5/weather?q=${city}&APPID=${apiKey}&units=${units}`;
        const response = await fetch(weatherApi);
        const data = await response.json();

        if(data.name)
        {
            this.setState({CityName: data.name});
            this.setState({Temperature: Math.floor(data.main.temp)});
            this.setState({Condition: data.weather[0].description});
            this.setState({degree: "°C"})
            this.setState({humidityValue: data.main.humidity});
            this.setState({humidity: "Humidity: "});
            this.setState({percent: "%"});
            this.setState({countryName: data.sys.country});
            this.setState({country: "Country: "});

        }
        
        else{
            this.setState({
                CityName: "Name Not Found",
                Temperature: null,
                Condition: null,
                degree:null,
                humidity:null,
                humidityValue:null,
                percent:null,
                country:null,
                countryName:null,
            })
        }
    }
    
    render() {
        return (
            <div  className="content">
                <p className="big1">Rainly</p>
                <div className="search-content">
                    <form onSubmit={this.getWeather} className="search-box-wrapper">
                        <input type="search" name="city" className="search-box-input" placeholder="Search around world"></input>
                        <button className="search-box-button" name="submit">&#128269;</button>
                    </form>
                </div>

                <div>
                    <p className="medium">{ this.state.CityName }</p>
                    <p className="medium">{ this.state.Temperature}{ this.state.degree}</p>
                    <p className="small">{ this.state.Condition }</p>
                    <p className="small">{ this.state.humidity}{ this.state.humidityValue}{ this.state.percent}</p>
                    <p className="small">{ this.state.country}{ this.state.countryName}</p>
                </div>
            </div>
        )
    }
}
